
import { downloadImage, getIOSMediaUrl } from "@evercam/shared/utils"
import ActionButton from "@evercam/shared/components/ActionButton"
import IosMediaDialog from "@/components/liveView/IosMediaDialog"
import Watermark from "@/components/Watermark"
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"
import { useWeatherStore } from "@/stores/weather"
import { useRecordingsStore } from "@/stores/recordings"
import { useCameraStore } from "@/stores/camera"
import { useTimelineStore } from "@/stores/timeline/timeline"
import { useTimelineCommentsGroupStore } from "@/stores/timeline/timelineCommentsGroup"
import { AnalyticsEvent } from "@evercam/shared/types/analytics"

export default {
  name: "ActionButtons",
  components: {
    ActionButton,
    IosMediaDialog,
    Watermark,
  },
  props: {
    isLiveView: {
      type: Boolean,
      default: false,
    },
    selectedCamera: {
      type: Object,
      default: () => ({}),
    },
    isLive: {
      type: Boolean,
      default: false,
    },
    isRecordings: {
      type: Boolean,
      default: false,
    },
    itwinButton: {
      type: Boolean,
      default: false,
    },
    isPlaying: {
      type: Boolean,
      default: true,
    },
    allowFullscreen: {
      type: Boolean,
      default: true,
    },
    allowDownload: {
      type: Boolean,
      default: true,
    },
    allowPlayPause: {
      type: Boolean,
      default: true,
    },
    isLiveViewWidget: {
      type: Boolean,
      default: false,
    },
    isRecordingWidget: {
      type: Boolean,
      default: false,
    },
    isWidget: {
      type: Boolean,
      default: false,
    },
    selectedTimestamp: {
      type: [String, Date],
      default: "",
    },
    timestamp: {
      type: [Number, Date, String, undefined],
      default: undefined,
    },
    isEdgeVideo: {
      type: Boolean,
      default: false,
    },
    hideXray: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSpecialAction: false,
      isWidgetPlaying: true,
      isMenuButtonActive: true,
      specialKeys: ["Shift", "Control"],
      iosMediaDialog: false,
      enableWidgetPlayPause: false,
      iosMediaUrl: "",
    }
  },
  computed: {
    ...mapStores(
      useRecordingsStore,
      useCameraStore,
      useLayoutStore,
      useWeatherStore,
      useTimelineStore,
      useTimelineCommentsGroupStore
    ),
    getSnapshotDownloadTitle() {
      let datetime
      if (this.isLive || this.isLiveView) {
        datetime = this.$moment().tz(this.selectedCamera.timezone)
      } else {
        datetime = this.$moment(this.selectedTimestamp).tz(
          this.selectedCamera.timezone
        )
      }
      const date = datetime.format("YYYY-MMMM-DD")
      const time = datetime.format("HH-mm")

      return `${this.selectedCamera.name}--${date}--${time} - Evercam Download`
    },
    setFullscreenIcon() {
      if (this.$device.isIos) {
        return "fas fa-expand"
      } else {
        return this.layoutStore.isFullscreen
          ? "fas fa-minimize"
          : "fas fa-expand-arrows-alt"
      }
    },
  },
  watch: {
    allowPlayPause(value) {
      if (!value) {
        this.enableWidgetPlayPause = false
      }
      this.toggleExpendableMenuButton()
    },
    allowDownload: "toggleExpendableMenuButton",
    allowFullscreen: "toggleExpendableMenuButton",
    "cameraStore.isCameraTab": "toggleExpendableMenuButton",
    isPlaying(value) {
      if (this.isLiveViewWidget && !value) {
        this.enableWidgetPlayPause = true
        this.toggleExpendableMenuButton()
      }
    },
  },
  mounted() {
    this.$addEventListener("keydown", this.handleKeyDown)
    this.$addEventListener("keyup", this.handleKeyUp)
    this.toggleExpendableMenuButton()
  },
  methods: {
    toggleAnnotations() {
      this.timelineCommentsGroupStore.allowComment(
        !this.timelineCommentsGroupStore.isAnnotationActive
      )
    },

    getSnapshotDownloadUrl() {
      return this.isLiveView || this.isLive
        ? this.$attrs?.image?.data
        : this.recordingsStore.getCurrentImageSrc()
    },
    openImageEditor() {
      this.recordingsStore.changeEditToolVisibility(true)
    },
    openWeatherDialog() {
      this.$fullscreen.exit()
      this.weatherStore.targetTimestamp =
        this.isLive || this.isLiveView ? null : this.selectedTimestamp

      this.weatherStore.changeWeatherVisibility(true)
    },
    toggleExpendableMenuButton() {
      this.isMenuButtonActive = false
      if (
        !this.cameraStore.isCameraTab &&
        !this.$route.path.includes("recordings")
      ) {
        this.isMenuButtonActive = true

        return
      }
      this.$setTimeout(() => {
        this.isMenuButtonActive =
          this.allowDownload ||
          this.allowPlayPause ||
          this.allowFullscreen ||
          this.enableWidgetPlayPause
      }, 200)
    },
    toggleIOSFullscreen() {
      this.cameraStore.isCameraTab = !this.cameraStore.isCameraTab
      this.layoutStore.toggleAppBarVisibility()
    },
    toggleFullscreen() {
      this.$fullscreen.toggle(this.$globalRefs.playerWrapper)
      if (this.isLiveView) {
        this.$analytics.saveEvent(AnalyticsEvent.PlayerToggleFullscreen, {
          active: !this.layoutStore.isFullscreen,
        })
      }
    },
    async downloadSnapshot(disableWatermark = false) {
      if (this.$device.isIos) {
        this.iosMediaUrl = await getIOSMediaUrl(this.getSnapshotDownloadUrl())
        this.iosMediaDialog = true
      } else if (disableWatermark || this.$device.isIE) {
        downloadImage(
          this.getSnapshotDownloadUrl(),
          this.getSnapshotDownloadTitle
        )
      } else {
        await this.downloadWatermarkedImage(this.getSnapshotDownloadTitle)
      }

      this.recordingsStore.isDownloading = false
      this.$analytics.saveEvent(AnalyticsEvent.PlayerDownload)
    },

    toggleXray() {
      this.recordingsStore.changeXrayVisibility(
        !this.recordingsStore.isXrayActive
      )
    },
    async downloadWatermarkedImage() {
      this.recordingsStore.isDownloading = true
      try {
        const response = await fetch(this.getSnapshotDownloadUrl()).then(
          (res) => res.blob()
        )
        const type = response.type.split("/")[1]
        const editImageDate = this.$moment()
          .tz(this.selectedCamera.timezone)
          .format("llll")
        this.$refs.watermark?.addWatermark(
          response,
          `${this.getSnapshotDownloadTitle}.${type}`,
          editImageDate,
          "download"
        )
      } catch (error) {
        downloadImage(
          this.getSnapshotDownloadUrl(),
          this.getSnapshotDownloadTitle,
          ""
        )
      } finally {
        this.recordingsStore.isDownloading = false
      }
    },
    handleKeyDown(e) {
      if (this.specialKeys.includes(e.key)) {
        this.isSpecialAction = true
      }
    },
    handleKeyUp(e) {
      if (this.specialKeys.includes(e.key)) {
        this.isSpecialAction = false
      }
    },
    toggleCalendar() {
      this.layoutStore.enableRightSidebar()
      this.$fullscreen.exit()
    },
    toggleCurrentPlayer() {
      this.$analytics.saveEvent(AnalyticsEvent.PlayerTogglePlayback, {
        active: !this.isPlaying,
      })
      this.$attrs.toggleCurrentPlayer()
    },
  },
}
